import React from "react";
import { connect } from "react-redux";

const Footer = (props) => {

  return (
    <>
      <footer id="footer" className="light-background text-white m-2">
        <div className="container">
          <div className="row g-4 justify-content-between">
            <div className="col-md-6 col-lg-6 mb-3 mb-md-0">
              <div class="widget">
                {/* <h3 class="widget-heading">About Us</h3> */}
                <p class="mb-4">
                  TJunction is your one-stop destination for discovering and connecting with the best talent and opportunities. Whether you're a recruiter looking to build your dream team, a student seeking your ideal career path, or a trainer passionate about sharing your knowledge, we've got you covered.
                </p>
                <p class="mb-4">
                  Join our vibrant community and experience the power of connection.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 ps-lg-5 mb-3 mb-md-0 align-items-right">
              <div className="widget">
               
                <ul class="list-unstyled float-start me-5">
                  <li className="my-3"><a href="#">About Us</a></li>
                  <li className="my-3"><a href="#">Contact Us</a></li>
                  <li className="my-3"><a href="#">FAQ's</a></li>
                  <li className="my-3"><a href="#">Terms and Conditions</a></li>
                  <li className="my-3"><a href="#">Report a Problem</a></li>
                  <li className="my-3"><a href="#">Privacy Policy</a></li>
                </ul>
              </div>
            </div>
          </div>

          <div className="text-white copyright text-center pb-4">
            <p>© {new Date().getFullYear()} <span>Copyright</span> <strong class="px-1 sitename">TJunction.</strong> <span>All Rights Reserved</span></p>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, null)(Footer);
