import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const StudentHeader = (props) => {
  return (
    <>
      <header className="header pt-1 fixed-top">
        <Navbar expand="lg" sticky="top">
          <div className="container-xxl">
            <Navbar.Brand className="tjunction-logo logo-marginleft">
              <Link to="/">
                {props.darkTheme ? (
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/tjunction_black_bg_logo.png"
                    }
                  />
                ) : (
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/tjunction_white_bg_logo.png"
                    }
                  />
                )}
              </Link>
            </Navbar.Brand>

            <div className="d-flex navmenu">
              <>
                <Link
                  to="/login"
                  className="btn btn-sm mx-2 font-weight-bold btn-light"
                >
                  Log In
                </Link>

                {/* <Link to="#" className="mx-1 small my-1">
                      Forgot password?
                    </Link> */}

                <Link
                  to="/register?role=student"
                  className={`${props.activeColor} btn btn-sm`}
                >
                  Register
                </Link>
              </>
            </div>
          </div>
        </Navbar>
      </header>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
});

export default connect(mapStateToProps, null)(StudentHeader);
