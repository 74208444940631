import { connect } from "react-redux";

import Header from "./Header";
import ThemeColor from "../ThemeColor";

const Layout = (props) => {
  return (
    <div className={props.darkTheme ? "is-dark-theme" : ""}>
      <Header />
      <div
        className="container-xxl primary-bg-color margin-top-80"
        style={{ backgroundColor: "#f2f9ff" }}
      >
        <div className="pb-0  py-3">
          <ThemeColor className="pb-0" />
        </div>
        {props.children}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkTheme: state.activetheme.darkTheme,
});

export default connect(mapStateToProps)(Layout);
