import {
  GET_RECRUITER_APPLIED_POST,
  GET_TRAINER_APPLIED_POST,
} from "../actions/types";

const myjobReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TRAINER_APPLIED_POST: {
      return {
        ...state,
        trainerpost: payload,
      };
    }

    case GET_RECRUITER_APPLIED_POST: {
      return {
        ...state,
        trainerpost: payload,
      };
    }

    default:
      return state;
  }
};
export default myjobReducer;
