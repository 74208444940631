import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import AuthRoute from "./routers/AuthRoute";
import PublicRoute from "./routers/PublicRoute";
import PrivateRoute from "./routers/PrivateRoute";
import TrainersPrivateRoute1 from "./routers/TrainersPrivateRoute1";
import { getUser, getUserRole } from "./utils/UserDetails";
import Loader from "react-loader-spinner";
import RecruiterLandingPage from "./pages/RecruiterLanding/recruiter-landing-page";
import TrainerLandingPage from "./pages/TrainerLanding/trainer-landing-page";
import JobDescriptionProcessing from "./pages/Trainers/dashboard/requirements/job/JobDescriptionProcessing";
// import AuthForm from "./pages/Auth/AuthForm";
// import ViewStudent from "./pages/Students";
// import ViewRecruiter from "./pages/Recruiter";
// import ViewTrainer from "./pages/Trainers";
// import PublicRoute from "./routers/PublicRoute";
// import EmailVerification from "./components/EmailVerification";
// import ProfileRender from "./pages/Trainers/profile/ProfileRender";
// import AllUser from "./pages/AllUser/AllUser";
// import Profile from "./pages/AllUser/Profile";
//  import CareerRoadMap from "./pages/AllUser/CareerRoadmap/CareerRoadMap";

// import CarrerProfile from "./pages/Student Landing/studentCareer/CarrerProfile";
// import CarrerRoadmap from "./pages/Student Landing/studentCareer/career-roadmap";
// import FitforRole from "./pages/Student Landing/studentCareer/FitforRole";
const AuthForm = lazy(() => import("./pages/Auth/AuthForm"));
const ViewStudent = lazy(() => import("./pages/Students"));
const ViewRecruiter = lazy(() => import("./pages/Recruiter"));
const ViewTrainer = lazy(() => import("./pages/Trainers"));
const EmailVerification = lazy(() => import("./components/EmailVerification"));
const ProfileRender = lazy(() =>
  import("./pages/Trainers/profile/ProfileRender"),
);
const AllUser = lazy(() => import("./pages/AllUser/AllUser"));
const Profile = lazy(() => import("./pages/AllUser/Profile"));
const CarrerProfile = lazy(() =>
  import("./pages/Student Landing/studentCareer/CarrerProfile"),
);
const CarrerRoadmap = lazy(() =>
  import("./pages/Student Landing/studentCareer/career-roadmap"),
);
const FitforRole = lazy(() =>
  import("./pages/Student Landing/studentCareer/FitforRole"),
);

class App extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     currentUser: getUser(), // Initialize state with getUser result
  //   };
  // }

  // componentDidMount() {
  //   window.addEventListener("storage", this.handleStorageChange);
  //   this.localStorageCheckInterval = setInterval(() => {
  //     const user = localStorage.getItem("api_token");
  //     if (user !== this.state.currentUser) {
  //       this.setState({ currentUser: user }); // Update state when getUser() changes
  //     }
  //   }, 1000); // Adjust interval as needed
  // }

  // componentWillUnmount() {
  //   // Clean up the event listener to avoid memory leaks
  //   window.removeEventListener("storage", this.handleStorageChange);
  // }
  constructor(props) {
    super(props);
    this.state = {
      currentUser: getUser(),
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("storage", this.handleStorageChange);
    this.localStorageCheckInterval = setInterval(() => {
      const user = localStorage.getItem("api_token");
      if (user !== this.state.currentUser && this._isMounted) {
        this.setState({ currentUser: user });
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("storage", this.handleStorageChange);
    clearInterval(this.localStorageCheckInterval);
  }

  handleStorageChange = (event) => {
    if (event.key === "userData") {
      const user = getUser();
      if (user !== this.state.currentUser) {
        this.setState({ currentUser: user });
      }
    }
  };

  render() {
    return (
      <div className="h-100">
        {this.state.currentUser ? (
          <>
            <Suspense
              fallback={
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={"80vh"}
                  width={50}
                  className="loader"
                />
              }
            >
              <Router>
                <Switch>
                  <TrainersPrivateRoute1
                    path="/trainer"
                    component={ViewTrainer}
                  />
                  <PublicRoute
                    path="/verify-user/:token/:email"
                    component={EmailVerification}
                  />
                  <PrivateRoute path="/student" component={ViewStudent} />
                  <PrivateRoute path="/recruiter" component={ViewRecruiter} />
                  <Redirect to={`/${getUserRole().toLowerCase()}/home`} />
                </Switch>
              </Router>
            </Suspense>
          </>
        ) : (
          <>
            <Suspense
              fallback={
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={"80vh"}
                  width={50}
                  className="loader"
                />
              }
            >
              <Router>
                <Switch>
                  <AuthRoute
                    path={["/login", "/register", "/"]}
                    component={AuthForm}
                    exact
                  />
                  <AuthRoute path="/student" component={CarrerRoadmap} />
                  <AuthRoute
                    path="/recruiter"
                    component={RecruiterLandingPage}
                  />
                  <AuthRoute path="/trainer" component={TrainerLandingPage} />
                  <AuthRoute
                    path="/career-roadmap"
                    component={CarrerProfile}
                    exact
                  />
                  <AuthRoute
                    path="/fit-for-role"
                    component={FitforRole}
                    exact
                  />
                  <PublicRoute
                    path="/verify-user/:token/:email"
                    component={EmailVerification}
                  />

                  <AuthRoute
                    path="/explore/:role"
                    component={AllUser}
                    exact={true}
                  />
                  <AuthRoute
                    path="/profile/:role"
                    component={Profile}
                    exact={true}
                  />
                  <AuthRoute
                    path="/profile-view/:id"
                    component={ProfileRender}
                    exact={true}
                  />
                  {/* for Recruiter */}
                  <AuthRoute
                    path="/explore/:role/job-description"
                    component={JobDescriptionProcessing}
                    exact={true}
                  />

                  <Redirect to="/" />
                </Switch>
              </Router>
            </Suspense>
          </>
        )}
      </div>
    );
  }
}

export default App;
