import {
  SET_TRAINING_POST,
  TRAINING_POST_COUNT,
  UPDATE_TRAINING,
  TRAINING_ERROR,
  TRAINING_SUCCESS,
  INSERT_TRAINING,
  DELETE_TRAINING_POST,
  SET_TRAINING_APPLY,
  TRAINING_APPLY_DETAILS,
  APPLY_DETAILS,
  CV_UPLOAD_AI,
} from "../actions/types";

const initialState = {
  trainingPost: [],
  trainingPostCount: 0,
  trainingFilter: {
    searchTerm: "",
    star_filter: 0,
    tab_view: "",
    category_id: "",
  },
  trainingError: "",
  trainingSuccess: "",
  trainingCategory: [
    {
      category_id: 1,
      category_name: "Beginners",
    },
    {
      category_id: 2,
      category_name: "Intermediate",
    },
    {
      category_id: 3,
      category_name: "Professional",
    },
  ],
  trainingApply: {
    isModalOpen: false,
    requirements_training_apply_requirements_id: "",
    requirements_training_apply_description: "",
    requirements_training_apply_no_of_days: "",
    requirements_training_apply_startdate: "",
    requirements_training_apply_enddate: "",
  },
  trainingApplyDetails: {
    isModalOpen: false,
    appliedDetailsData: [],
  },
  ApplyDetails: {
    isModalOpen: false,
    appliedDetailsData: [],
  },
  cvData:[],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TRAINING_POST:
      return {
        ...state,
        trainingPost: payload.isCreation
          ? payload.trainingPost
          : state.trainingPost.concat(payload.trainingPost),
      };

    case INSERT_TRAINING:
      return { ...state, trainingPost: [payload, ...state.trainingPost] };

    case TRAINING_POST_COUNT:
      return { ...state, trainingPostCount: payload };

    case UPDATE_TRAINING:
      return {
        ...state,
        trainingPost: state.trainingPost.map((post) =>
          post.requirements_training_id === payload.requirements_training_id
            ? { ...post, ...payload }
            : post,
        ),
      };

    case DELETE_TRAINING_POST:
      return {
        ...state,
        trainingPost: state.trainingPost.filter(
          (post) =>
            post.requirements_training_id !== payload.requirements_training_id,
        ),
      };

    case SET_TRAINING_APPLY:
      return {
        ...state,
        trainingApply: payload,
      };

    case TRAINING_APPLY_DETAILS:
      return {
        ...state,
        trainingApplyDetails: { ...state.trainingApplyDetails, ...payload },
      };
    case APPLY_DETAILS:
      return {
        ...state,
        ApplyDetails: { ...state.ApplyDetails, ...payload },
      };

    case TRAINING_SUCCESS:
      return { ...state, trainingSuccess: payload };

    case TRAINING_ERROR:
      return { ...state, trainingError: payload };
      

    case CV_UPLOAD_AI:
      return {
        ...state,
        cvData: payload,
      };

    default:
      return state;
  }
};
export default reducer;
