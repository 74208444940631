import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUserRole } from "../utils/UserDetails";

function PrivateRoute({ component: Component, ...rest }) {
  let redirectLink = "/";

  if (getUserRole() === "STUDENT") {
    redirectLink = "/student";
  } else if (getUserRole() === "RECRUITER") {
    redirectLink = "/recruiter";
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        getUserRole() === "STUDENT" || getUserRole() === "RECRUITER" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectLink,
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
