import {
  TRAINING_EXPERIENCE,
  JOB_EXPERIENCE,
  QUALIFICATIONS,
  CERTIFICATIONS,
  AWARDS,
  SKILLS,
  DESCRIPTION,
  DELETE,
  RECORD_HIDE,
  CREATE_NEW_PROFILE,
  SECTION_HIDE,
  ENDORSE_DETAILS,
  EDIT_PROFILE_MENU,
  SET_PRIMARY_PROFILE,
  ACTIVE_PROFILE,
  USER_PROFILES,
  CREATE_INTEREST,
  GET_INTERESTS,
  GET_PROFILE,
  UPLOAD_IMAGE,
  PROFILE_PREVIEW,
} from "../actions/types";

const initialState = {
  userProfiles: [],
  activeProfile: {},
  profilepreview: {},
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_PROFILES: {
      return {
        ...state,
        userProfiles: action.userProfiles,
      };
    }
    case ACTIVE_PROFILE: {
      return {
        ...state,
        activeProfile: action.activeProfile,
      };
    }
    case PROFILE_PREVIEW: {
      return {
        ...state,
        profilepreview: action.profilepreview,
      };
    }
    case TRAINING_EXPERIENCE: {
      return {
        ...state,
        training: action.payload,
      };
    }
    case JOB_EXPERIENCE: {
      return {
        ...state,
        job: action.payload,
      };
    }
    case QUALIFICATIONS: {
      return {
        ...state,
        qualification: action.payload,
      };
    }
    case CERTIFICATIONS: {
      return {
        ...state,
        certificates: action.payload,
      };
    }
    case AWARDS: {
      return {
        ...state,
        awards: action.payload,
      };
    }
    case SKILLS: {
      return {
        ...state,
        skills: action.payload,
      };
    }
    case DESCRIPTION: {
      return {
        ...state,
        description: action.payload,
      };
    }
    case DELETE: {
      return {
        ...state,
        delete: action.payload,
      };
    }
    case RECORD_HIDE: {
      return {
        ...state,
        recordHide: action.payload,
      };
    }
    case CREATE_NEW_PROFILE: {
      return {
        ...state,
        recordHide: action.payload,
      };
    }
    case SECTION_HIDE: {
      return {
        ...state,
        section: action.payload,
      };
    }
    case ENDORSE_DETAILS: {
      return {
        ...state,
        endorseDetails: action.payload,
      };
    }
    case EDIT_PROFILE_MENU: {
      return {
        ...state,
        editProfile: action.payload,
      };
    }
    case SET_PRIMARY_PROFILE: {
      return {
        ...state,
        userProfiles: state.userProfiles.map((profile) =>
          profile.user_profile_id === action.payload.profileID
            ? {
                ...profile,
                user_profile_default: 1,
              }
            : {
                ...profile,
                user_profile_default: 0,
              },
        ),
      };
    }
    case CREATE_INTEREST: {
      return {
        ...state,
        interest: action.payload,
      };
    }
    case GET_INTERESTS: {
      return {
        ...state,
        allInterests: action.payload,
      };
    }
    case GET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }
    case UPLOAD_IMAGE: {
      return {
        ...state,
        image: action.payload,
      };
    }
    default:
      return state;
  }
};

export default profileReducer;
