import axios from "axios";
import moment from "moment-timezone";
import {
  getTokenExpires,
  getUser,
  setUserSession,
  setTokenExpires,
  removeUserSession,
} from "../utils/UserDetails";

const baseURL = process.env.REACT_APP_BACKEND_BASEURL + "/api";
const headerData = {
  Accept: "application/json",
};
const currentUser = getUser();
let isRefresh = 1;
let currentTime = moment().tz("UTC").unix();
let token = getUser();
let isTokenExpiredData = null;
let isTokenExpired = null;
let callOut = null;
let originalRequest = null;

if (currentUser) {
  headerData.Authorization = `Bearer ${currentUser}`;
}
const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: headerData,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    currentTime = moment().tz("UTC").unix();
    token = getUser();
    isTokenExpiredData = getTokenExpires()
      ? getTokenExpires().split("-")
      : null;
    isTokenExpired = isTokenExpiredData?.[0]
      ? parseInt(isTokenExpiredData[0], 10)
      : null;
    callOut = isTokenExpiredData?.[1]
      ? parseInt(isTokenExpiredData[1], 10)
      : null;
    originalRequest = config;

    if (
      token &&
      isTokenExpiredData &&
      isTokenExpired < currentTime &&
      callOut < currentTime
    ) {
      removeUserSession();
      window.location.href = "/login";
      console.log("session expired");
    }
    originalRequest.headers.Authorization = `Bearer ${token}`;
    return originalRequest;
  },
  (err) => {
    console.log("error in getting ", err);
  },
);

axiosInstance.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  },
);

export default axiosInstance;
